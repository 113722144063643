<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-card
      class="py-6 px-8 mx-2"
      elevation="0"
    >
      <v-row class="mt-8">
        <v-col>
          <label
            for="entity"
            class="pt-4 txt16-gray50"
          >
            Entitas <span class="symbol-required">*</span>
          </label>
          <v-combobox
            id="entity"
            v-model="vEntity"
            class="mt-2"
            :items="entity"
            color="#36AC87"
            item-text="name"
            item-value="ent_hash"
            placeholder="Pilih Entitas"
            outlined
            dense
          />
        </v-col>
      </v-row>

      <v-row class="mt-n6">
        <v-col
          class="pt-4 txt16-gray50"
          cols="12"
          sm="4"
        >
          Sub Entitas
        </v-col>
      </v-row>

      <v-row
        v-for="v in subEntityLength"
        :key="'entity-' + v"
        class="mt-n2 mb-n8"
      >
        <v-col>
          <v-combobox
            :id="'sub-entity-' + v"
            v-model="vSubEntity[v - 1]"
            :items="entity"
            color="#36AC87"
            item-text="name"
            item-value="ent_hash"
            placeholder="Pilih Sub Entitas"
            outlined
            dense
          />
        </v-col>
      </v-row>

      <v-row class="mt-n6 mb-4">
        <v-col>
          <v-btn
            class="text-capitalize"
            color="#36AC87"
            block
            outlined
            @click="vSubEntity.push(''); subEntityLength += 1"
          >
            Tambah Kolom
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label
            class="txt16-gray50"
            for="judul"
          >Judul Artikel <span class="symbol-required">*</span></label>
          <v-text-field
            id="judul"
            v-model="judul"
            placeholder="Masukan Judul Artikel"
            outlined
            dense
          />
        </v-col>
      </v-row>

      <v-row class="mt-n8">
        <v-col>
          <label
            class="txt16-gray50"
            for="isi"
          >Tanggal Artikel <span class="symbol-required">*</span></label>
          <div
            data-app
            class="mt-2"
          >
            <v-dialog
              ref="dialog"
              v-model="modalTanggal"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  placeholder="Masukan tanggal artikel"
                  readonly
                  color="#36AC87"
                  v-bind="attrs"
                  dense
                  outlined
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="date"
                scrollable
                color="#36AC87"
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="modalTanggal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label
            class="txt16-gray50"
            for="isi"
          >Isi Artikel <span class="symbol-required">*</span></label>
          <vue-editor
            id="isi"
            v-model="isi"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="px-0">
          <label
            class="mx-3 txt16-gray50"
            for="lampiran"
          >Foto atau Dokumen</label>
          <upload-button
            id="lampiran"
            :is-loading="isLoading"
            :upload-files="uploadedFiles"
            multiple
            @uploadButtonClick="uploadFile"
          />
          <v-file-input
            id="upload-file"
            accept="image/*"
            style="display: none"
            multiple
            @change="upload"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <span
            v-for="(v, i) in uploadedFiles"
            :key="'img-' + i"
            class="mx-3"
          >
            <v-badge
              bordered
              color="error"
              icon="mdi-close"
              overlap
              offset-x="8"
              offset-y="8"
              style="cursor: pointer"
              @click.native="deleteImage(i)"
            >
              <v-img
                :src="v.file_path_thumb ? v.file_path_thumb : v.url_thumb"
                width="200px"
                height="200px"
              />
            </v-badge>
          </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-right">
          <v-btn
            color="#2E976C"
            class="mx-1 text-capitalize"
            style="font-weight: bold; color: white"
            @click="modal = true"
          >
            Simpan
          </v-btn>
          <v-btn
            color="#CCCCCC"
            class="mx-1 text-capitalize"
            style="font-weight: bold; color: white"
            @click="navBack"
          >
            Batal
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <!-- {{ uploadedFiles }} -->

    <modal-konfirmasi
      :show="modal"
      @submit="confirm"
    />
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { VueEditor } from 'vue2-editor'
  import UploadButton from '../komponen/UploadButton.vue'
  import ModalKonfirmasi from '../komponen/modal/ModalKonfirmasi.vue'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
      VueEditor,
      UploadButton,
      ModalKonfirmasi,
    },

    data: () => ({
      judul: '',
      isi: '',
      modal: false,
      isLoading: false,
      uploadedFile: null,
      fileHash: '',
      files: [],
      modalTanggal: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      uploadedFiles: [],
      entity: [],
      vEntity: null,
      subEntityLength: 3,
      subEntity: '',
      vSubEntity: [],
    }),

    computed: {
    },

    watch: {
    },

    created () {
      this.initialize()
      this.getEntity()
    },

    methods: {
      closeDialog () {
        this.dialog = false
      },

      navBack () {
        this.$router.push({ name: 'Artikel' })
      },

      uploadFile () {
        document.getElementById('upload-file').click()
      },

      confirm (p) {
        // console.log(p)
        if (p === 0) {
          this.modal = false
        } else {
          this.modal = false
          this.submit()
        }
      },

      deleteImage (p) {
        // console.log(p)
        this.uploadedFiles.splice(p, 1)
      },

      getEntity () {
        axios.post('/v1/general/get-entity').then((res) => {
          if (res.data.status === 200) {
            this.entity = res.data.data.entity.list
          }
        })
      },

      initialize () {
        const requestBody = {
          article_hash: this.$route.params.id,
        }

        axios.post('/v1/admin/article/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.vEntity = res.data.data.entity_main
            this.judul = res.data.data.title
            this.isi = res.data.data.content
            this.date = res.data.data.date_raw
            this.uploadedFiles = res.data.data.list_file
            res.data.data.sub_entity.map(v => {
              this.vSubEntity.push(v)
            })
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      updateStatus (p) {
        const requestBody = {
          article_hash: p,
        }

        axios.post('/v1/admin/article/update-status', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      async upload (e) {
        this.isLoading = true
        const length = e.length
        for (let i = 0; i < length; i++) {
          const res = await this.postUpload(e[i])
          if (res === 'NOK') {
            this.$toast.error('Upload file(s) failed')
            this.uploadedFiles = []
            this.isLoading = false
            return
          }
        }
        this.isLoading = false
      },

      postUpload (e) {
        // console.log(e)
        return new Promise(resolve => {
          const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
          const requestBody = new FormData()
          requestBody.append('file', e)
          requestBody.append('dest', 'member_photo')

          axios.post(baseCDNurl + '/v1/upload', requestBody, config).then((res) => {
            if (res.data.status === 200) {
              this.uploadedFiles.push(res.data.data)
              resolve('OK')
            }
          })
            .catch((e) => {
              resolve('NOK')
              if (typeof (e.response.data.error) === 'object') {
                this.$toast.error(Object.values(e.response.data.error)[0][0])
              } else {
                this.$toast.error(e.response.data.error)
              }
            })
        })
      },

      submit () {
        const files = []
        this.uploadedFiles.map(v => {
          if (v.hash) files.push(v.hash)
          if (v.tmp_file_hash) files.push(v.tmp_file_hash)
        })

        const subEntity = []
        this.vSubEntity.map(v => {
          if (v !== '') subEntity.push(v.ent_hash)
        })

        const requestBody = {
          article_hash: this.$route.params.id,
          title: this.judul,
          date_article: this.date,
          content: this.isi,
          list_file: files,
          ent_hash: this.vEntity.ent_hash,
          list_sub_ent: subEntity,
        }

        axios.post('/v1/admin/article/update', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.judul = ''
            this.isi = ''
            this.fileHash = ''
            this.$toast.success(res.data.message)
            this.navBack()
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0][0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.symbol-required {
  color: $red-1;
}
</style>
